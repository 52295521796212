import basketballtraining from '../../../static/GrassLake/img1.png'
import basketballtimes from "../../../static/GrassLake/img2.png"
import activities from '../../../static/GrassLake/img3.png'
import activities2 from '../../../static/GrassLake/img4.png'




import './GrassLakeSpring'

function Carousel() {
    return (
        <div id="carouselItems" className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators">
                <li data-target="#carouselItems" data-slide-to="0" className="active"></li>
                <li data-target="#carouselItems" data-slide-to="1"></li>
                <li data-target="#carouselItems" data-slide-to="2"></li>
                <li data-target="#carouselItems" data-slide-to="3"></li>

            </ol>
            <div className="carousel-inner">
            <div className="carousel-item active">
                  <img src={activities} className="d-block w-100" alt="Basketball Training"/>
                  <div className='carousel-caption d-none d-md-block'>
                    <p>Join us for fun and engaging activites in our after care club</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img src={activities2} className="d-block w-100" alt="Training Times"/>
                  <div className='carousel-caption d-none d-md-block'>
                    <p>Every week is a new theme at aftercare</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img src={basketballtraining} className="d-block w-100" alt="Activities"/>
                  <div className='carousel-caption d-none d-md-block'>
                    <p>Or join our sports club to sharpen your skills</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img src={basketballtimes} className="d-block w-100" alt="Activities"/>
                  <div className='carousel-caption d-none d-md-block'>
                    <p>Sign up until 2:45PM or 4:00PM</p>
                  </div>
                </div>
            </div>
            <a className="carousel-control-prev" href="#carouselItems" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselItems" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
            </a>
        </div>
    );
};

export default Carousel;