import {games, trainingInfoBasketball, wednesdayBasketball} from './PlayTimeData'
import PlayTymeLogo from "../../static/PlayTymeLogo.png"
import PurchaseList from '../../components/PurchaseList';
import Location from '../../components/Location.jsx'
import LogisiticsDetails from '../../components/LogisiticsDetails';
import 'animate.css';

export default function PlayTymeBasketball() {
  return (
    <section className="section d-flex flex-column align-items-center justify-content-center">
      <div className="info camps__container animate__animated animate__fadeInRight">
        <h4>Basketball Training Wednesdays Jan 15th to Feb 19th</h4>
        <h4 className='disclaimer'>Ages 4 to 6 -  9:30AM to 10:15AM</h4>
        <div className='camp__container'>
          <div className="purchase">
            <PurchaseList
            type="ThreeDayBundle"
            header="Wednesday 9:30AM to 10:15AM"
            subhead="$90/ 6 Classes"
            data={wednesdayBasketball}
          />
        <LogisiticsDetails 
            header="Activities"
            data={games}        
          />
        </div>

        <div className='logistics'>
        <img
                className="img-fluid mb-4 pl-0"
                src={PlayTymeLogo}
                alt="PlayTyme Logo"
              />
        <Location 
            link="https://maps.app.goo.gl/ZGyhKdSmUq8K7fcA7"
            name="Play Tyme Gym"
            street="1500 E Grand Ave"
            city=" Lindenhurst, IL 60046"
          />
          <LogisiticsDetails 
            header="Important Notes"
            data={trainingInfoBasketball}
          />
        </div>

      </div>
      {/* close camp container div */}
    </div>
    {/* close info div */}
  </section>
)
}