const trainingOption1Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/pop-in-training-w-coach-greg-dec-17-andor-19/",
    name: "2 Day Bundle T/TH",
    date: "$55",
  },
]

const trainingOption2Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/pop-in-training-w-coach-greg-dec-17-andor-19/",
    name: "1 Day Only",
    date: "$30",
  },
]

const tuesdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/pop-in-training-w-coach-greg-dec-17-andor-19/",
    name: "Dec 17th",
    date: "$30",
  },
]

const wednesdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/pop-in-training-w-coach-greg-dec-17-andor-19/",
    name: "Dec 4th",
    date: "$30",
  },
]

const thursdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/pop-in-training-w-coach-greg-dec-17-andor-19/",
    name: "Dec 19th",
    date: "$30",
  },
]


const trainingInfo = [
  "Bring a basketball and a water bottle.",
  "3rd Grade to 6th Grade 6pm to 7:30pm.",
  "7th to 12th Grade: 7:30pm to 9pm."
]

const trainingDrills = [
  "Triple Threat",
  "Stationary Ball Handling",
  "Ball Handling",
  "Attacking the Basket",
  "Layups",
  "Form Shooting",
  "Footwork",
  "Transition",
  "Three Man Weave",
  "Passing",
  "Passing Off the Dribble",
  "Defense",
  "Rebounding",
  "and more!"
]



export {trainingDrills, trainingInfo, tuesdayTrainingOption, wednesdayTrainingOption, thursdayTrainingOption,trainingOption1Bundle, trainingOption2Bundle}