const mondaySoccer = [
  {
    link: "https://playyon.com/606-academy/programs/monday-soccer-at-play-tyme-gym-ages-4-to-6/",
    name: "6 Classes Ages 4 to 6",
    date: "$90",
  },
]

const tuesdayFootbal = [
  {
    link: "https://playyon.com/606-academy/programs/tuesday-football-at-playtyme-gym-ages-4-to-6/",
    name: "6 Classes Ages 4 to 6",
    date: "$90",
  },
]

const wednesdayBasketball = [
  {
    link: "https://playyon.com/606-academy/programs/wednesday-basketball-at-play-tyme-gym-ages-4-to-6p/",
    name: "6 Classes Ages 4 to 6",
    date: "$90",
  },
]


const mondayBasketballKto2 = [
  {
    link: "https://playyon.com/606-academy/programs/monday-530-to-630pm-grades-1-and-2-playtyme-gym/",
    name: "6 Classes Grades K to 2",
    date: "$90",
  },
]

const trainingInfoSoccer = [
  "Bring a ball, athletic shoes and a water bottle.",
  "Jan 13, Jan 20, Jan 27, Feb 3, 10, and 17,"
]

const trainingInfoFootball = [
  "Bring a ball, athletic shoes and a water bottle.",
  "Jan 14, Jan 21, Jan 28, Feb 4, 11, and 18,"
]

const trainingInfoBasketball = [
  "Bring a ball, athletic shoes and a water bottle.",
  "Jan 15, Jan 22, Jan 29, Feb 5, 12, and 19,"
]

const trainingInfoBasketballKto2 = [
  "Bring a ball, athletic shoes and a water bottle.",
  "Jan 13, Jan 20, Jan 27, Feb 3, 10, and 17,"
]

const games = [
  "Fun Games",
  "Passing",
  "Sharks and Minnows",
  "Red Light, Green Light",
  "Freeze Tag",
  "Sleeping Dragon",
  "Making Friends",
  "Transition",
  "Learn to Love the Game",
  "Just the Basics",
  "More games",
  "and much much more!"
]

const drills = [
  "Triple Threat",
  "Stationary Ball Handling",
  "Ball Handling",
  "Attacking the Basket",
  "Layups",
  "Form Shooting",
  "Footwork",
  "Transition",
  "Three Man Weave",
  "Passing",
  "Passing Off the Dribble",
  "Defense",
  "Rebounding",
  "and more!"
]



export {games, drills, trainingInfoSoccer, trainingInfoFootball, trainingInfoBasketball, trainingInfoBasketballKto2, wednesdayBasketball, mondayBasketballKto2, mondaySoccer, tuesdayFootbal}