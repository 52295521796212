const grasslakeFall2023option1Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/grass-lake-after-school-fridays-115-to-400pm-grass/",
    name: "18 Enrichment Sessions",
    date: "1:15-4:00 PM",
  },
]

const grasslakeFall2023option2Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/grass-lake-after-school-fridays-115-to-245pm-grass/",
    name: "18 Enrichment Sessions",
    date: "1:15-2:45 PM",
  },
]
const grasslakeFall2023option1IndividualSessions = [
  {
    link: "https://playyon.com/606-academy/programs/dec-to-jan-grass-lake-after-school-fridays-115-to-/",
    name: "December 6th",
    date: "January 31st",
  },
  {
    link: "https://playyon.com/606-academy/programs/feb-7-to-apr-4-grass-lake-after-school-fridays-115/",
    name: "February 7th",
    date: "April 4th",
  },
  {
    link: "https://playyon.com/606-academy/programs/apr-11-to-may-23-grass-lake-after-school-fridays-1/",
    name: "April 11th",
    date: "May 23rd",
  },
]

const grasslakeFall2023option2IndividualSessions = [
  {
    link: "https://playyon.com/606-academy/programs/dec-to-jan-grass-lake-after-school-fridays-115-t-2/",
    name: "December 6th",
    date: "January 31st",
  },
  {
    link: "https://playyon.com/606-academy/programs/feb-7th-to-apr-4th-grass-lake-after-school-fridays/",
    name: "February 7th",
    date: "April 4th",
  },
  {
    link: "https://playyon.com/606-academy/programs/apr-11th-to-may-23rd-grass-lake-after-school-frida/",
    name: "April 11th",
    date: "May 23rd",
  },
]

const grasslakeIndividualSessions = [
  {
    link: "https://playyon.com/606-academy/programs/grass-lake-dec-6th/",
    name: "December 6th",
    date: "$12 to $20",
  },
  {
    link: "https://playyon.com/606-academy/programs/grass-lake-dec-13th/",
    name: "December 13th",
    date: "$12 to $20",
  },
  {
    link: "https://playyon.com/606-academy/programs/grass-lake-jan-10th/",
    name: "January 10th",
    date: "$12 to $20",
  },
  {
    link: "https://playyon.com/606-academy/programs/grass-lake-jan-17th/",
    name: "January 17th",
    date: "$12 to $20",
  },
  {
    link: "https://playyon.com/606-academy/programs/jan-24-grass-lake/",
    name: "January 24th",
    date: "$12 to $20",
  },
  {
    link: "https://playyon.com/606-academy/programs/jan-31-grass-lake/",
    name: "January 31st",
    date: "$12 to $20",
  },
]


const grasslakeFall2023AInfo = [
  "During registration you can select our after care option or all sports option.",
  'Switching between All Sports and Aftercare is not allowed.',
  "No class held on 12/20; 12/27; 1/3; 2/14; 3/21; 3/28; 4/18;",
  "ONLY Grass Lake School students are eligible to sign up for this enrichment program",
  "Individual day registrations are available upon request.",
  "Held on early release Fridays",
  "Bring athletic shoes and a water bottle.",
  "K to 8th Grade welcome",
]

const grasslakeFall2023Drills = [
  "Basketball",
  "Soccer",
  "Dodgeball",
  "Volleyball",
  "Floor Hockey",
  "Football",
  "and much much more!"
]

const grasslakeFallActivites = [
  "Dec 6th Camping",
  "Dec 13th Snow Day",
  "Jan 10th Game Party",
  "Jan 17th Beach Day",
  "Jan 24th Pajama Party",
  "Jan 31st TBA",
  "Puzzles",
  "Crafts",
  "Movies",
  "and much much more"
]



export {grasslakeFall2023Drills, grasslakeFall2023AInfo, grasslakeFall2023option1IndividualSessions, grasslakeFall2023option2IndividualSessions, grasslakeFall2023option1Bundle, grasslakeFall2023option2Bundle, grasslakeFallActivites, grasslakeIndividualSessions}